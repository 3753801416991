<a *ngIf="IsRoutingField; else readOnlyField" (click)="RoutingFieldClickedHandler()"
   href="javascript:void(0)">{{DisplayValue}}</a>
<ng-template #readOnlyField>
    {{DisplayValue}}
</ng-template>
<div *ngIf="hijriEnabled" class="calendar-toggle">
    <p-dropdown [options]="options"
                [(ngModel)]="selectedCalendar"
                (onChange)="toggle()"
                optionLabel="label">
        <ng-template let-item pTemplate="selectedItem">
            {{item.value}}
        </ng-template>
    </p-dropdown>
</div>

