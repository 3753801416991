import { StructuredDataEntryModel } from '@landadmin/structured-data/lib/models/structured-data-entry-model';
import { createAction, props } from '@ngrx/store'
import { StructuredDataListWidgetState } from '../../AppState';
import { StructuredDataEntryCreateDTO } from '../../data-transfer-objects/structured-data/entry/structured-data-entry-create-dto';
import { StructuredDataEntryUpdateDTO } from '../../data-transfer-objects/structured-data/entry/structured-data-entry-update-dto';
import { WidgetLoadMode } from '../../enums/widget-load-mode';
import { PaginationModel } from '../../models/pagination-models';

//LOAD STRUCTURED DATA
export const loadStructuredDataEntryRequest = createAction('[StructuredData] Load Structured Data Entry Request',
    props<{
        pageId: string,
        rootNodeId: string,
        entityId: string,
        paginationModel: PaginationModel,
        triggeredWidgetId: string,
        loadMode: WidgetLoadMode
    }>());

export const loadStructuredDataEntryDone = createAction('[StructuredData] Load Structured Data Entry Done',
    props<{
        rootNodeId: string,
        structuredDataListWidget: StructuredDataListWidgetState
    }>());

export const loadStructuredDataEntryError = createAction('[StructuredData] Load Structured Data Entry Error',
    props<{
        rootNodeId: string,
        structuredDataListWidget: StructuredDataListWidgetState
    }>());


//UPDATES
export const updateStructuredDataEntryRequest = createAction('[StructuredData] Update Structured Data Entry Request',
    props<{
        pageId: string,
        rootNodeId: string,
        entityId: string,
        structuredDataUpdateModel: StructuredDataEntryUpdateDTO,
        structuredDataEntries: StructuredDataEntryModel[],
        displayNextPage: boolean,
        totalRecords: number,
        triggeredWidgetId: string,
        loadMode: WidgetLoadMode
    }>());

export const updateStructuredDataEntryDone = createAction('[StructuredData] Update Structured Data Entry Done',
    props<{
        rootNodeId: string,
        structuredDataListWidget: StructuredDataListWidgetState
    }>());

export const updateStructuredDataEntryError = createAction('[StructuredData] Update Structured Data Entry Error',
    props<{
        rootNodeId: string,
        structuredDataListWidget: StructuredDataListWidgetState
    }>());


//ADDS
export const createStructuredDataEntryRequest = createAction('[StructuredData] Create Structured Data Entry Request',
    props<{
        pageId: string,
        rootNodeId: string,
        entityId: string,
        structuredDataCreateModel: StructuredDataEntryCreateDTO,
        structuredDataEntries: StructuredDataEntryModel[],
        displayNextPage: boolean,
        totalRecords: number,
        triggeredWidgetId: string,
        loadMode: WidgetLoadMode,
        navigateToLastPage: boolean
    }>());

export const createStructuredDataEntryDone = createAction('[StructuredData] Create Structured Data Entry Done',
    props<{
        rootNodeId: string,
        structuredDataListWidget: StructuredDataListWidgetState
    }>());

export const createStructuredDataEntryError = createAction('[StructuredData] Create Structured Data Entry Error',
    props<{
        rootNodeId: string,
        structuredDataListWidget: StructuredDataListWidgetState
    }>());


//DELETES
export const deleteStructuredDataEntryRequest = createAction('[StructuredData] Delete Structured Data Entry Request',
    props<{
        pageId: string,
        rootNodeId: string,
        entityId: string,
        parentEntryId: string,
        structuredDataEntries: StructuredDataEntryModel[],
        displayNextPage: boolean,
        totalRecords: number,
        triggeredWidgetId: string,
        loadMode: WidgetLoadMode,
        reloadPage: boolean
    }>());

export const deleteStructuredDataEntryDone = createAction('[StructuredData] Delete Structured Data Entry Done',
    props<{
        rootNodeId: string,
        structuredDataListWidget: StructuredDataListWidgetState
    }>()
);

export const deleteStructuredDataEntryError = createAction('[StructuredData] Delete StructStructuredued Data Entry Error',
    props<{
        rootNodeId: string,
        structuredDataListWidget: StructuredDataListWidgetState
    }>());


//RESET STATE
export const resetStructuredDataEntry = createAction('[StructuredData] Reset Structured Data Entry');
