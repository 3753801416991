<fw-list (OnLoad)="OnLoad($event)"
         (RoutingFieldClicked)="NavigateByContext($event)"      
         (SelectableFieldClicked)="itemSelected($event)"
         [ListConfigurationViewDTO]="AssetListWidgetConfigurationViewDTO"
         [ErrorMessageResourceId]="errorMessageResourceId"
         [PaginationResultModel]="paginationResultModelCopy"
         [IsUserList]="false"
         [CustomHeaderTemplate]="CustomHeaderTemplate"
         [AdditionalColumnsBodyTemplate]="CustomBodyTemplate"
         [selectMode]="selectMode"
         [deleteEnabled]="deleteEnabled"
         [IsSelectable]="true">
</fw-list>

<ng-template #CustomHeaderTemplate let-data>
    <tr>
        <th style="width: 30px">
            <!--Select Placeholder-->
        </th>
        <th *ngFor="let col of data">
            {{ col.HeaderLabelResourceId | transloco }}
        </th>
    </tr>
</ng-template>


<ng-template #CustomBodyTemplate let-data>
    <p-dropdown *ngIf="showOptionItems" [options]="relationshipTypes" optionLabel="Value" optionValue="Id" fwAutoFocus [(ngModel)]="data.SelectedRelationshipTypeId" appendTo="body">
    </p-dropdown>
</ng-template>

<div class="p-dialog-footer justify-content-end">
    <p-button styleClass="btn btn-lg" type="button" (onClick)="cancelClicked()" label="{{'SelectDocumentType.Form.Cancel' | transloco }}"></p-button>
    <p-button [disabled]="!canProceed" styleClass="btn btn-primary btn-lg" type="button" (onClick)="proceedClicked()" label="{{'SelectDocumentType.Form.Proceed' | transloco }}"></p-button>
</div>

