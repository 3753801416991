import { createReducer, on } from '@ngrx/store';
import { ListState, StructuredDataListWidgetState } from '../../AppState';
import { ListStateReducerHelper } from '../../helpers/list-state-reducer-helper';
import { createStructuredDataEntryDone, createStructuredDataEntryError, deleteStructuredDataEntryDone, deleteStructuredDataEntryError, loadStructuredDataEntryDone, loadStructuredDataEntryError, resetStructuredDataEntry, updateStructuredDataEntryDone, updateStructuredDataEntryError } from './structured-data.actions';


//Assets


export const initialStructuredDataState: ListState<StructuredDataListWidgetState>[] = [];

export const StructuredDataListReducer = createReducer<ListState<StructuredDataListWidgetState>[]>(initialStructuredDataState,
    on(loadStructuredDataEntryDone, (state, action) => ListStateReducerHelper.GetUpdatedListState<StructuredDataListWidgetState>(state, action.rootNodeId, action.structuredDataListWidget)),
    on(loadStructuredDataEntryError, (state, action) =>  ListStateReducerHelper.GetUpdatedListState<StructuredDataListWidgetState>(state, action.rootNodeId, action.structuredDataListWidget) ),

    on(updateStructuredDataEntryDone, (state, action) => ListStateReducerHelper.GetUpdatedListState<StructuredDataListWidgetState>(state, action.rootNodeId, action.structuredDataListWidget) ),
    on(updateStructuredDataEntryError, (state, action) => ListStateReducerHelper.GetUpdatedListState<StructuredDataListWidgetState>(state, action.rootNodeId, action.structuredDataListWidget) ),

    on(createStructuredDataEntryDone, (state, action) => ListStateReducerHelper.GetUpdatedListState<StructuredDataListWidgetState>(state, action.rootNodeId, action.structuredDataListWidget) ),
    on(createStructuredDataEntryError, (state, action) =>ListStateReducerHelper.GetUpdatedListState<StructuredDataListWidgetState>(state, action.rootNodeId, action.structuredDataListWidget) ),

    on(deleteStructuredDataEntryDone, (state, action) => ListStateReducerHelper.GetUpdatedListState<StructuredDataListWidgetState>(state, action.rootNodeId, action.structuredDataListWidget) ),
    on(deleteStructuredDataEntryError, (state, action) => ListStateReducerHelper.GetUpdatedListState<StructuredDataListWidgetState>(state, action.rootNodeId, action.structuredDataListWidget) ),

    on(resetStructuredDataEntry, () => initialStructuredDataState),
);


