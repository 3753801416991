import { RelatedAssetListWidgetAdditionalConfigurationData, RelatedAssetListWidgetConfigurationViewDTO } from '../data-transfer-objects/configuration/widgets/related-asset-list-widget-configuration-view-dto';

export class RelatedAssetHelper {
    public static mapRelatedAssetListWidgetConfigurationViewDTOToAssetListWidgetConfigurationViewDTO(relatedAssetListWidgetConfigurationViewDTO: RelatedAssetListWidgetConfigurationViewDTO, showOptionItems: boolean): RelatedAssetListWidgetAdditionalConfigurationData {
        return {
            AssetListWidgetConfigurationViewDTO: {
                AssetQueryDefinition: {
                    Parties: relatedAssetListWidgetConfigurationViewDTO.AddFilters.Filters.Parties,
                    SavedSearchId: relatedAssetListWidgetConfigurationViewDTO.AddFilters.Filters.SavedSearchId,
                    RelatedAsset: null
                },
                ContainerContentType: relatedAssetListWidgetConfigurationViewDTO.ContainerContentType,
                DefaultSort: relatedAssetListWidgetConfigurationViewDTO.DefaultSort,
                DefaultSortOrder: relatedAssetListWidgetConfigurationViewDTO.DefaultSortOrder,
                EmptyListResourceId: relatedAssetListWidgetConfigurationViewDTO.EmptyListResourceId,
                Fields: relatedAssetListWidgetConfigurationViewDTO.AddFields,
                Id: relatedAssetListWidgetConfigurationViewDTO.Id,
                PageSize: relatedAssetListWidgetConfigurationViewDTO.PageSize,
                HideWhenEmpty: relatedAssetListWidgetConfigurationViewDTO.HideWhenEmpty,
                HideWhileLoading: relatedAssetListWidgetConfigurationViewDTO.HideWhileLoading,
                Section: relatedAssetListWidgetConfigurationViewDTO.Section,
                UISortingEnabled: relatedAssetListWidgetConfigurationViewDTO.UISortingEnabled,
                UIFilteringEnabled: relatedAssetListWidgetConfigurationViewDTO.UIFilteringEnabled,
                Widget: relatedAssetListWidgetConfigurationViewDTO.Widget,
                AddEnabled: false,
                DeleteEnabled: false,
                Required: false,
                LayoutMode: relatedAssetListWidgetConfigurationViewDTO.LayoutMode,
                SelectMode: relatedAssetListWidgetConfigurationViewDTO.SelectMode,
                HeaderResourceId: relatedAssetListWidgetConfigurationViewDTO.HeaderResourceId,
                HeaderStyle: relatedAssetListWidgetConfigurationViewDTO.HeaderStyle,
                DisplayStyle: relatedAssetListWidgetConfigurationViewDTO.DisplayStyle,
                Add: relatedAssetListWidgetConfigurationViewDTO.Add
            },
            ShowOptionItems: showOptionItems
        }
    }
}