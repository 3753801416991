import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Buffer } from 'buffer';
import { Observable, of, Subscription, timer } from 'rxjs';
import { LandingPageBaseRoute } from '../../consts/portal-base-routes';
import { severity_error, severity_success } from '../../consts/severity-options';
import { LoginDTO } from '../../data-transfer-objects/login/login-dto';
import { RepresentativeOfListViewDTO } from '../../data-transfer-objects/representative/representative-list-view-dto';
import { SessionViewDTO } from '../../data-transfer-objects/session/session-view-dto';
import { UserViewDTO } from '../../data-transfer-objects/user/user-view-dto';
import { CurrentUserRepresentativeUpdateDTO } from '../../data-transfer-objects/users/current-user-representative-update-dto';
import { GuidHelper } from '../../helpers/guid-helper';
import { ClientUserModel } from '../../models/client-user-model';
import { PaginationResultModel } from '../../models/pagination-models';
import { SwitchRepresentationComponent } from '../../modules/static/forms/switch-representation/switch-representation.component';
import { AuthenticationHttpService } from '../http/authentication-http.service';
import { RepresentativesHttpService } from '../http/representatives-http.service';
import { RootHttpService } from '../http/root-http.service';
import { UserHttpService } from '../http/user-http.service';
import {
    DialogApplicationService,
    DialogOptions
} from './dialog-application.service';
import { ToastApplicationService } from './toast-application.service';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { IdPsConfigurationFacade } from "../../facade/configuration/idp.facade";
import { IdPConfigurationViewDTO } from "../../data-transfer-objects/configuration/idp-configuration-view-dto";
import { LoginType } from "../../enums/login-type";
import { LoginFormComponent } from "../../modules/static/forms/login-form/login-form.component";
import { IdpSelectionFormComponent } from "../../modules/static/forms/idp-selection-form/idp-selection-form.component";
import { UserAccountStatus } from "../../enums/user-account-status-enum";
import { AllSessionKeys, SessionKey } from "../../consts/session-storage-keys";
import { ErrorDetails } from "../../models/error-details";

@Injectable({
    providedIn: 'root',
})
export class SessionApplicationService {
    private setAuthenticationToken(token: string) {
        this._isAuthenticated = null;
        localStorage.setItem(SessionKey, token);
    }

    private clearAuthenticationToken() {
        this._isAuthenticated = null;

        // instead of using localStorage.clear() remove all keys we don't have constants for
        // this function should remove any local storage stuff not managed by framework
        // or old keys we no longer use

        let toRemove: string[] = [];

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            
            if (AllSessionKeys.filter(x => x != SessionKey).includes(key)) {
                continue;
            }

            toRemove.push(key);
        }
        
        for (const key of toRemove) {
            localStorage.removeItem(key);
        }
    }

    public get authenticationToken(): string {
        return localStorage.getItem(SessionKey);
    }

    public get isAuthenticated(): boolean {
        const value = this.tokenValid(this.authenticationToken);

        if (this._isAuthenticated == null) {
            this._isAuthenticated = value;
        } else {
            //if local storage indicates we're not authenticated, but we think we're authenticated then assume
            //we've been logged out somewhere else (a different tab) and force a logout
            //similarly if local storage says we're actually authenticated then redirect to the landing page and refresh the menus

            if (value != this._isAuthenticated) {
                this._isAuthenticated = value;
                this._authenticationChanged.emit([value, null]);
            }
        }

        return value;
    }
    
    public get hasRoles(): boolean {
        return this.currentUser.Roles.length > 0;
    }

    public get currentUser(): UserViewDTO {
        if (!this.isAuthenticated) {
            return null;
        }
        
        const tokenObj = this.getToken(this.authenticationToken);
        
        return tokenObj 
            ? {
                Id: tokenObj.Id,
                PersonId: tokenObj.PersonId,
                IsDefaultRepresentation: tokenObj.IsDefaultRepresentation == "True",
                RepresenteeId: tokenObj.RepresenteeId,
                Roles: tokenObj.Roles.split(','),
                Firstname: tokenObj.Firstname,
                Surname: tokenObj.Surname,
                Fullname: tokenObj.Fullname,
                Username: tokenObj.Username,
                Email: tokenObj.Email,
                EmailConfirmed: tokenObj.EmailConfirmed == "True",
                PhoneNumber: tokenObj.PhoneNumber,
                LockoutEnabled: tokenObj.LockoutEnabled == "True",
                RepresenteeName: tokenObj.RepresenteeName,
                AccountStatusId: tokenObj.AccountStatusId,
                TwoFactorEnabled: tokenObj.TwoFactorEnabled == "True",
                Idp: tokenObj.Idp
            }
            : null;
    }

    public get currentUserHasRepresentatives(): boolean {
        return !GuidHelper.IsEmpty(this.currentUser?.RepresenteeId);
    }

    private _authenticationChanged: EventEmitter<[boolean,string]> = new EventEmitter<[boolean,string]>();
    public oneTimePinRequired: EventEmitter<null> = new EventEmitter();
    public authenticationFailed: EventEmitter<ErrorDetails> = new EventEmitter();

    private tokenExpirySubscription: Subscription;
    private _isAuthenticated?: boolean;

    constructor(
        private rootHttpService: RootHttpService,
        private authenticationHttpService: AuthenticationHttpService,
        private userHttpService: UserHttpService,
        private dialogApplicationService: DialogApplicationService,
        private toastApplicationService: ToastApplicationService,
        private translocoService: TranslocoService,
        private representativesService: RepresentativesHttpService,
        private router: Router,
        private idPsConfigurationFacade: IdPsConfigurationFacade
    ) { }

    public GetSessionInformation(): SessionViewDTO {
        return {
            RepresenteeName: this.currentUser.RepresenteeName,
            UserEmail: this.currentUser.Email,
            UserFirstName: this.currentUser.Firstname,
            UserFullName: this.currentUser.Fullname,
            UserPhoneNumber: this.currentUser.PhoneNumber,
            UserSurname: this.currentUser.Surname
        }
    }

    public getCurrentClientUser(): Observable<ClientUserModel> {
        return this.userHttpService.GetCurrentUser();
    }
    
    public get authenticationChanged(): Observable<boolean> {
        return this._authenticationChanged
            .asObservable()
            .pipe(map(x => x[0]));
    }
    
    public init(): void {
        this._authenticationChanged.subscribe(authenticationChange => {
            const authenticated = authenticationChange[0];
            const returnUrl = authenticationChange[1];

            if (!authenticated) {
               if (this.tokenExpirySubscription) {
                   this.tokenExpirySubscription.unsubscribe();
               }

               this.tokenExpirySubscription = undefined;
               
               this.clearAuthenticationToken();

               this.router.navigate([LandingPageBaseRoute]);
            } else {
               this.initalizeAutoLogoutMechanism();

               if (this.currentUser.AccountStatusId === UserAccountStatus.ProfileDetailsRequired) {
                   this.router.navigate([`page/UserDetails`], {
                       queryParams: { UserId: this.currentUser.Id },
                   });
               } else if (returnUrl) {
                   this.router.navigateByUrl(returnUrl);
               } else {
                   this.router.navigate([LandingPageBaseRoute]);
               }
           } 
        });
        
        if (this.isAuthenticated) {
            this.initalizeAutoLogoutMechanism();
        }
    }

    public initalizeAutoLogoutMechanism(previousToken?: string): void {
        if (this.tokenExpirySubscription) {
            this.tokenExpirySubscription.unsubscribe();
        }
        
        const expiryInSeconds = this.getExpiry(this.authenticationToken);

        //we're not authenticated
        if (expiryInSeconds <= 0) {
            this.logout();
            return;
        }

        //if the previous token is the same as the current token, the server is no longer refreshing the token
        if (previousToken === this.authenticationToken) {
            this.tokenExpirySubscription = this.activeTimer(expiryInSeconds, false)
                .subscribe(() => {
                    this.logout();
                });
        } else {
            //wait half the amount of time until expiry and then refresh the token
            this.tokenExpirySubscription = this.activeTimer(expiryInSeconds / 2.0, true)
                .subscribe(() => {
                    this.userHttpService.RefreshToken().subscribe((token: string) => {
                        const previousToken = this.authenticationToken;
                        this.setAuthenticationToken(token);
                        this.initalizeAutoLogoutMechanism(previousToken);
                    }, error => {
                        if (error?.AnonymousErrorObject?.status === 401) {
                            this.logout();
                        } else {
                            this.initalizeAutoLogoutMechanism(previousToken);
                        }
                    });
                });
        }
    }

    public login(url: string): void {
        this.idPsConfigurationFacade.ResetState();
        this.idPsConfigurationFacade.loadIdPsConfiguration(false);

        this.idPsConfigurationFacade.getIdPsConfiguration()
            .pipe(filter(dto => !!dto), first())
            .subscribe((idPConfigurationViewDTO: IdPConfigurationViewDTO) => {
                const internalIdpEnabled = !!idPConfigurationViewDTO.InternalIdentityProviderConfiguration?.Enabled;
                const hasIdps = !!idPConfigurationViewDTO.Saml2IdPs?.length;
                const hasSingleIdp = !internalIdpEnabled && idPConfigurationViewDTO.Saml2IdPs?.length === 1;
                const returnUrl = url;

                if (internalIdpEnabled && !hasIdps) {
                    if (returnUrl) {
                        this.router.navigate(['/page/login'], {
                            queryParams: { ReturnUrl: returnUrl }
                        });
                    } else {
                        this.router.navigate(['/page/login']);
                    }
                } else if (hasSingleIdp) {
                    this.saml2Authentication(idPConfigurationViewDTO.Saml2IdPs[0].Name, returnUrl).subscribe((saml2RedirectUrl) => {
                        window.location.href = saml2RedirectUrl;
                    });
                } else {
                    const dialogOptions: DialogOptions<LoginDTO> = {
                        closable: true,
                        showHeader: true,
                        footer: '',
                        header: this.translocoService.translate('Login.Form.SignInTitle'),
                        dataModel: {
                            logintype: LoginType.UsernamePassword,
                            username: '',
                            password: '',
                            onetimepin: '',
                            returnUrl: returnUrl
                        },
                    };

                    this.dialogApplicationService.showFormDialog(
                        LoginFormComponent,
                        dialogOptions
                    );
                }
            });
    }

    public register(): void {
        this.idPsConfigurationFacade.ResetState();
        this.idPsConfigurationFacade.loadIdPsConfiguration(true);

        this.idPsConfigurationFacade.getIdPsConfiguration()
            .pipe(filter(dto => !!dto), first())
            .subscribe((idPConfigurationViewDTO: IdPConfigurationViewDTO) => {
                if (idPConfigurationViewDTO.InternalIdentityProviderConfiguration?.Enabled && idPConfigurationViewDTO.Saml2IdPs?.length === 0) {
                    this.router.navigate(['/page/register']);
                } else if (!idPConfigurationViewDTO.InternalIdentityProviderConfiguration?.Enabled && idPConfigurationViewDTO.Saml2IdPs?.length === 1) {
                    window.location.href = idPConfigurationViewDTO.Saml2IdPs[0].RedirectUrl;
                } else {
                    const dialogOptions: DialogOptions<IdPConfigurationViewDTO> = {
                        closable: true,
                        showHeader: true,
                        footer: '',
                        header: this.translocoService.translate('IdPSelection.Form.RegistrationOptions'),
                        dataModel: idPConfigurationViewDTO
                    };

                    this.dialogApplicationService.showFormDialog(
                        IdpSelectionFormComponent,
                        dialogOptions
                    );
                }
            });
    }
    
    public logout(): void {
        this._authenticationChanged.emit([false, null]);
    }
    
    public tryLoginWithToken(token: string, returnUrl: string): void {
        if (returnUrl) {
            if (returnUrl.startsWith(window.location.origin)) {
                returnUrl = returnUrl.substring(window.location.origin.length);
            } else {
                returnUrl = null;
            }
        }
        
        if (this.tokenValid(token)) {
            this.setAuthenticationToken(token);
            
            if (!this.currentUser) {
                this.DisplayToast('SessionApplicationService.Login.LoginFailedDetail', 'SessionApplicationService.Login.LoginFailedUserDetailsNotPopulated', severity_error);
                this.logout();
            } else if (GuidHelper.IsEmpty(this.currentUser?.PersonId)) {
                this.DisplayToast('SessionApplicationService.Login.LoginFailedDetail', 'SessionApplicationService.Login.LoginFailedUserPersonNotPopulated', severity_error);
                this.logout();
            } else {
                this.getRepresentatives(this.currentUser).subscribe(_ => {
                    this.DisplayToast('SessionApplicationService.Login.LoginSuccessfulSummary', 'SessionApplicationService.Login.LoginSuccessfulDetail', severity_success);
                    this._authenticationChanged.emit([true, returnUrl]);
                });
            }
        } else {
            this.setAuthenticationToken('');
            this.authenticationFailed.emit();
        }
    }

    public tryLogin(loginDto: LoginDTO, rememberClient: boolean, languageId: string, returnUrl: string): void {
        this.authenticationHttpService.logIn(loginDto, rememberClient, languageId).subscribe(
            (bearerTokenViewDTO) => {
                if (bearerTokenViewDTO.TwoFactorEnabled) {
                    this.oneTimePinRequired.emit();
                } else {
                    this.tryLoginWithToken(bearerTokenViewDTO.Token, returnUrl);
                }
            },
            error => {
                this.authenticationFailed.emit(error.error);
            }
        );
    }

    public switchRepresentation(): void {
        this.representativesService
            .GetRepresentatives(this.currentUser.PersonId)
            .subscribe((representatives: PaginationResultModel<RepresentativeOfListViewDTO>) => {
                    const dialogOptions: DialogOptions<RepresentativeOfListViewDTO[]> = {
                        closable: false,
                        showHeader: true,
                        footer: '',
                        header: this.translocoService.translate(
                            'SwitchRepresentation.Form.Title'
                        ),
                        dataModel: representatives.Models,
                        styleClass: 'nested-footer dialog-md',
                    };

                    const dialogRef = this.dialogApplicationService.showFormDialog(
                        SwitchRepresentationComponent,
                        dialogOptions
                    );

                    dialogRef.onClose.subscribe((currentUserRepresentativeUpdateDTO: CurrentUserRepresentativeUpdateDTO) => {
                            if (currentUserRepresentativeUpdateDTO) {
                                this.userHttpService
                                    .updateRepresentation(currentUserRepresentativeUpdateDTO)
                                    .subscribe(token => {
                                        this.setAuthenticationToken(token.Token);
                                        this.router.navigate([LandingPageBaseRoute]);
                                    });
                            }
                        }
                    );
                }
            );
    }
    
    private getRepresentatives(user: UserViewDTO): Observable<void> {
        if (this.currentUserHasRepresentatives) {
            return of(null);
        }

        return this.representativesService.GetRepresentatives(user.PersonId)
            .pipe(mergeMap((representatives: PaginationResultModel<RepresentativeOfListViewDTO>) => {
                if (representatives.Models.length === 1) {
                    return this.userHttpService.updateRepresentation({
                            RepresentativeId: representatives.Models[0].Id,
                            IsDefaultRepresentation: user.IsDefaultRepresentation,
                        })
                        .pipe(
                            tap(token => {
                                this.setAuthenticationToken(token.Token);
                            }),
                            map(token => {
                                return;
                            })
                        );
                } else if (representatives.Models.length > 1) {
                    const dialogOptions: DialogOptions<RepresentativeOfListViewDTO[]> = {
                        closable: false,
                        showHeader: true,
                        footer: '',
                        header: this.translocoService.translate(
                            'SwitchRepresentation.Form.Title'
                        ),
                        dataModel: representatives.Models,
                        styleClass: 'nested-footer dialog-md',
                    };

                    const dialogRef =
                        this.dialogApplicationService.showFormDialog(
                            SwitchRepresentationComponent,
                            dialogOptions
                        );

                    return dialogRef.onClose.pipe(mergeMap((currentUserRepresentativeUpdateDTO: CurrentUserRepresentativeUpdateDTO) => {
                        if (currentUserRepresentativeUpdateDTO) {
                            return this.userHttpService.updateRepresentation(currentUserRepresentativeUpdateDTO)
                                .pipe(
                                    tap(token => {
                                        this.setAuthenticationToken(token.Token);
                                    }),
                                    map(token => {
                                        return;
                                    })
                                );
                        } else {
                            this.logout();
                        }
                    }));
                }
            }));
    }
    
    public sendOneTimePin(username: string): void {
        this.authenticationHttpService.sendOneTimePin(username).subscribe();
    }

    public saml2Authentication(idpName: string, returnUrl: string): Observable<string> {
        return this.authenticationHttpService.saml2Authentication(idpName, returnUrl);
    }

    private activeTimer(duration: number, countActiveSeconds: boolean): Observable<number> {
        if (countActiveSeconds) {
            return timer(0, 1000)
                .pipe(
                    filter(n => !document.hidden && n > duration),
                    first(),
                );
        } else {
            return timer(duration * 1000);
        }
    }

    private DisplayToast(summary: string, detail: string, severity: string): void {
        this.toastApplicationService.showToast(summary, detail, severity);
    }

    private tokenValid(token: string): boolean {
        return this.getExpiry(token) > 0;
    }

    private getToken(token: string): any {
        if (token) {
            return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('ascii'));
        }
        
        return null;
    }
    
    private getExpiry(token: string): number {
        let expiry: number = 0;

        if (token) {
            const expiryUnixTimeInSeconds: number = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('ascii')).exp;
            const currentUnixTimeInSeconds: number = new Date().getTime() / 1000;

            expiry = expiryUnixTimeInSeconds - currentUnixTimeInSeconds;
        }

        return expiry > 0
            ? expiry
            : 0;
    }
}
