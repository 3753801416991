import { EventEmitter, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DynamicPageBaseRoute } from '../../consts/portal-base-routes';
import { MenuItemConfigurationViewDTO } from '../../data-transfer-objects/configuration/menu-item-configuration-view-dto';
import { NavigationLinkModel } from '../../models/navigation-link-model';
import { SessionApplicationService } from '../application/session-application.service';
import { ConfigurationHttpService } from '../http/configuration-http.service';
import { SignalRApplicationService } from '../application/signalr-base-application-service';
import { HttpStatusCode } from "../../enums/http-status-codes";

@Injectable({
    providedIn: 'root'
})
export class MenuItemApplicationService {
    private _loading: boolean = false;
    private _mainMenuLinks: NavigationLinkModel[] = [];
    private _mainMenuLinkCountEmitter = new EventEmitter<number>();
    
    public get MainMenuLinks(): NavigationLinkModel[] {
        if (this._loading) {
            return [];
        } else {
            return this._mainMenuLinks;
        }
    }
    
    public set MainMenuLinks(value: NavigationLinkModel[]) {
        this._mainMenuLinks = value;
        this._mainMenuLinkCountEmitter.emit(value.length);
    }
    
    constructor(
        private sessionApplicationService: SessionApplicationService,
        private configurationService: ConfigurationHttpService,
        private signalRApplicationService: SignalRApplicationService,
        private translocoService: TranslocoService
    ) {
        this.sessionApplicationService.authenticationChanged.subscribe(() => {
            this.getMenuItems();
        });
        
        this.signalRApplicationService.Connected.subscribe((connected) => {
            if (connected) {
                this.getMenuItems();
            }
        });

        this._mainMenuLinkCountEmitter.subscribe(menuItemCount => {
            if (menuItemCount === 0) {
                setTimeout(self => { self.getMenuItems(); }, 1000, this);
            }
        })
    }

    public getMenuItems() {
        this._loading = true;

        this.configurationService.GetMenuItems().subscribe(
            (menuItems: MenuItemConfigurationViewDTO[]) => {
                this.MainMenuLinks = this.convertDynamicSiteMenuToNavigationLinks(menuItems);
                this._loading = false;
            }, error => {
                if (error?.status !== HttpStatusCode.SERVICE_UNAVAILABLE) {
                    this.MainMenuLinks = [];
                    this._loading = false;
                }
            });
    }

    private convertDynamicSiteMenuToNavigationLinks(menuItems: MenuItemConfigurationViewDTO[]) {
        const navigationLinkModels: NavigationLinkModel[] = [];
        
        if (menuItems) {
            menuItems.forEach((parentMenuItem: MenuItemConfigurationViewDTO) => {
                let subMenu: NavigationLinkModel[] = [];
                if (parentMenuItem.MenuItems && parentMenuItem.MenuItems.length > 0) {
                    subMenu = this.convertDynamicSiteMenuToNavigationLinks(parentMenuItem.MenuItems);
                }
                navigationLinkModels.push(new NavigationLinkModel(this.translocoService.translate(parentMenuItem.MenuItemResourceId), `${DynamicPageBaseRoute}/${parentMenuItem.PageId}`, null, subMenu));
            });
        }
        
        return navigationLinkModels;
    }
}
