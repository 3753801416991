import { createReducer, on } from '@ngrx/store';
import { WidgetStateCache } from '../../AppState';
import { resetStateCache, setStateCache } from './state-cache.actions';

export const intialMapReferenceListState: WidgetStateCache = {};

export const StateCacheReducer = createReducer<WidgetStateCache>(intialMapReferenceListState,
    on(setStateCache, (state, action) => {
        return Object.assign({}, state, { [action.key]: action.data });
    }),

    on(resetStateCache, () => intialMapReferenceListState)
);
