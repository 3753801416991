import { Injectable } from '@angular/core';
import { StructuredDataLibraryService } from '@landadmin/structured-data';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getStructuredDataLookupConfigurationState } from '../../state/configuration/configuration.selectors';
import { loadStructuredDataLookupsRequest, resetStructuredDataLookUps } from '../../state/configuration/structured-data/look-up/structured-data-look-up.actions';
import { AppState, StructuredDataLookupConfigurationState } from '../../AppState';

@Injectable({
    providedIn: 'root'
})
export class StructuredDataLookupFacade {

    constructor(private store: Store<AppState>, public _structuredDataLibraryService: StructuredDataLibraryService) {
    }

    public LoadStructuredDataLookups(): void {

        this.store.dispatch(loadStructuredDataLookupsRequest());
    }

    public LoadStructuredDataLookupsSelector(): Observable<StructuredDataLookupConfigurationState> {

        const structuredDataWidgetState = this.store.pipe(select(getStructuredDataLookupConfigurationState));
        return structuredDataWidgetState;
    }

    public ResetState(): void {
        this._structuredDataLibraryService.RegisterLookupConfiguration(null);
        this.store.dispatch(resetStructuredDataLookUps());
    }
}
