import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { SiteSettingsFacade } from '../../../../../facade/site-settings/site-settings.facade';
import { ConfigurableControlsHelper } from '../../../../../helpers/configurable-controls-helper';
import { CalendarType, HijriDateHelper } from '../../../../../helpers/date-helper';
import { LocaleDateFormatPipe } from '../../../../../pipes/locale-date-format.pipe';
import { SiteSettingsService } from '../../../../../services/deprecated/site-settings.service';
import { LanguageHttpService } from '../../../../../services/http/language-http.service';

@Component({
    selector: 'fw-list-calendar-wrapper',
    templateUrl: './list-calendar-wrapper.component.html',
})
export class ListCalendarWrapperComponent {

    public IsHijri: boolean = false;

    @Input()
    public Date: Date;

    @Input()
    public IsRoutingField: boolean;

    @Output()
    public RoutingFieldClicked: EventEmitter<void> = new EventEmitter<void>();

    public DisplayValue: string;

    public selectedCalendar: { type: CalendarType, label?: string, value?: string } = { type: CalendarType.Gregorian };
    public hijriEnabled: boolean = false;
    public options: any[] = ConfigurableControlsHelper.GetCalendarOptions(this.transloco);

    private Subscriptions: Subscription[] = [];

    constructor(private siteSettingsFacade: SiteSettingsFacade,
        private transloco: TranslocoService,
        private siteSettingsService: SiteSettingsService,
        private languageHttpService: LanguageHttpService
    ) {
    }

    ngOnInit() {
        this.Subscriptions.push(this.siteSettingsFacade.getCalendars().subscribe(calendars => {
            this.hijriEnabled = calendars?.Hijri?.Enabled;
        }));

        this.toggle();
    }

    ngOnDestroy(): void {
        if (this.Subscriptions) {
            this.Subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            });
        }
    }

    toggle() {
        this.IsHijri = this.selectedCalendar.type === CalendarType.Hijri;

        if (this.IsHijri) {
            const hijriDate = ConfigurableControlsHelper.ConvertGregorianToHijri(new Date(this.Date));
            this.DisplayValue = HijriDateHelper.formatDate(hijriDate.day, hijriDate.month, hijriDate.year, this.siteSettingsService.dateFormat, this.languageHttpService.getCurrentLanguageLocale());
        } else {
            this.DisplayValue = new LocaleDateFormatPipe(this.languageHttpService, this.siteSettingsService).transform(new Date(this.Date))
        }
    }

    RoutingFieldClickedHandler() {
        this.RoutingFieldClicked.emit();
    }
}
