import { Injectable } from '@angular/core';
import { StructuredDataEntryModel } from '@landadmin/structured-data/lib/models/structured-data-entry-model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { ErrorDetails } from 'src/app/models/error-details';
import { StructuredDataListWidgetState } from '../../AppState';
import { WidgetLoadMode } from '../../enums/widget-load-mode';
import { PaginationResultModel } from '../../models/pagination-models';
import { StructuredDataEntryHttpService } from '../../services/http/structured-data-entry-http.service';
import { createStructuredDataEntryDone, createStructuredDataEntryError, createStructuredDataEntryRequest, deleteStructuredDataEntryDone, deleteStructuredDataEntryError, deleteStructuredDataEntryRequest, loadStructuredDataEntryDone, loadStructuredDataEntryError, loadStructuredDataEntryRequest, updateStructuredDataEntryDone, updateStructuredDataEntryError, updateStructuredDataEntryRequest } from './structured-data.actions';


@Injectable()
export class StructuredDataEntryEffects {

    loadStructuredDataEntries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadStructuredDataEntryRequest),
            mergeMap((action) => {
                return this.structuedDataService.getStructuredDataEntry(action.pageId, action.rootNodeId, action.entityId, action.paginationModel).pipe(
                    map((pagingResultModel: PaginationResultModel<StructuredDataEntryModel>) => {
                        return loadStructuredDataEntryDone({
                            rootNodeId: action.rootNodeId,
                            structuredDataListWidget: this.buildStructuredDataListWidget(action.triggeredWidgetId, action.loadMode, null, null, null, null, false, false, pagingResultModel)
                        });
                    }),
                    catchError((errorDetails: ErrorDetails) => {
                        return of(
                            loadStructuredDataEntryError(
                                {
                                    rootNodeId: action.rootNodeId,
                                    structuredDataListWidget: this.buildStructuredDataListWidget(action.triggeredWidgetId, action.loadMode, null, null, errorDetails, null, false, false,null)
                                }
                            )
                        );
                    })
                );
            })
        ));



    addRemoveUpdateEntry$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateStructuredDataEntryRequest, createStructuredDataEntryRequest, deleteStructuredDataEntryRequest),
            concatMap((action) => {

                if (action.type === deleteStructuredDataEntryRequest.type) {
                    return this.structuedDataService.deleteStrucutredDataEntry(action.pageId, action.rootNodeId, action.entityId, action.parentEntryId).pipe(
                        map(() => {
                            return deleteStructuredDataEntryDone({
                                rootNodeId: action.rootNodeId,
                                structuredDataListWidget: this.buildStructuredDataListWidget(action.triggeredWidgetId, action.loadMode, null, null, null, null, false, action.reloadPage, {
                                    DisplayNextPage: action.displayNextPage,
                                    Models: action.structuredDataEntries,
                                    TotalRecords: action.totalRecords
                                })
                            });
                        }),
                        catchError((errorDetails: ErrorDetails) => {
                            return of(
                                deleteStructuredDataEntryError(
                                    {
                                        rootNodeId: action.rootNodeId,
                                        structuredDataListWidget: this.buildStructuredDataListWidget(action.triggeredWidgetId, action.loadMode, null, errorDetails, null, null, false, false, null)
                                    }
                                )
                            );
                        })
                    );
                }

                else if (action.type === createStructuredDataEntryRequest.type) {
                    return this.structuedDataService.createStrucutredDataEntry(action.pageId,
                        action.rootNodeId,
                        action.entityId,
                        action.structuredDataCreateModel).pipe(
                            map(() => {
                                return createStructuredDataEntryDone({
                                    rootNodeId: action.rootNodeId,
                                    structuredDataListWidget: this.buildStructuredDataListWidget(action.triggeredWidgetId, action.loadMode, null, null, null, null, action.navigateToLastPage, false, {
                                        DisplayNextPage: action.displayNextPage,
                                        Models: action.structuredDataEntries,
                                        TotalRecords: action.totalRecords
                                    })
                                });
                            }),
                            catchError((errorDetails: ErrorDetails) => {
                                return of(
                                    createStructuredDataEntryError(
                                        {
                                            rootNodeId: action.rootNodeId,
                                            structuredDataListWidget: this.buildStructuredDataListWidget(action.triggeredWidgetId, action.loadMode, errorDetails, null, null, null, false, false, null)
                                        }
                                    )
                                );
                            })
                        );
                }

                else {

                    return this.structuedDataService.updateStructuredDataEntry(action.pageId, action.rootNodeId, action.entityId, action.structuredDataUpdateModel).pipe(
                        map(() => {
                            return updateStructuredDataEntryDone({
                                rootNodeId: action.rootNodeId,
                                structuredDataListWidget: this.buildStructuredDataListWidget(action.triggeredWidgetId, action.loadMode, null, null, null, null, false, false, {
                                    DisplayNextPage: action.displayNextPage,
                                    Models: action.structuredDataEntries,
                                    TotalRecords: action.totalRecords,
                                })
                            });
                        }),
                        catchError((errorDetails: ErrorDetails) => {
                            return of(
                                updateStructuredDataEntryError(
                                    {
                                        rootNodeId: action.rootNodeId,
                                        structuredDataListWidget: this.buildStructuredDataListWidget(action.triggeredWidgetId, action.loadMode, null, null, null, errorDetails, false, false, null)
                                    }
                                )
                            );
                        })
                    );
                }
            })
        ));


    constructor(
        private actions$: Actions,
        private structuedDataService: StructuredDataEntryHttpService
    ) { }

    private buildStructuredDataListWidget(widgetId: string, loadMode: WidgetLoadMode, addError: ErrorDetails, deleteError: ErrorDetails, getError: ErrorDetails, updateError: ErrorDetails, navigateToLastPage: boolean, reloadPage: boolean, pagedStructuredDataEntryViewModel: PaginationResultModel<StructuredDataEntryModel>): StructuredDataListWidgetState {
        return {
            addError: addError,
            deleteError: deleteError,
            getError: getError,
            updateError: updateError,
            viewModel: pagedStructuredDataEntryViewModel,
            loadMode: loadMode,
            widgetId: widgetId,
            navigateToLastPage: navigateToLastPage,
            reloadPage: reloadPage
        }
    }
}
