import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RelatedAssetAddDTO } from 'src/app/data-transfer-objects/related-asset/related-asset-add-dto';
import { RelatedAssetListViewDTO } from 'src/app/data-transfer-objects/related-asset/related-asset-list-view-dto';
import { AssetListViewDTO } from '../../data-transfer-objects/asset/asset-list-view-dto';
import { AssetSearchModel } from '../../data-transfer-objects/asset/asset-search-dto';
import { AssetUpdateDTO } from '../../data-transfer-objects/asset/asset-update-dto';
import { AssetViewDTO } from '../../data-transfer-objects/asset/asset-view-dto';
import { RelatedAssetSearchModel } from '../../data-transfer-objects/related-asset/related-asset-search-dto';
import { HttpStatusCode } from '../../enums/http-status-codes';
import { BASE_URL } from '../../helpers/base-url';
import { HttpFieldModel } from '../../models/field-list-model';
import { PaginationModel, PaginationResultModel } from '../../models/pagination-models';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class RelatedAssetHttpService extends AuthenticatedHttpServiceBase {
    constructor(httpClient: HttpClient, @Inject(BASE_URL) private baseUrl: string, errorService: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorService, toastService);
    }

    public getRelatedAssetsBySearch(paginationModel: PaginationModel, relatedAssetSearchDTO: RelatedAssetSearchModel, fieldModel: HttpFieldModel, pageId: string): Observable<PaginationResultModel<RelatedAssetListViewDTO>> {
        return this.PostAuthenticated<PaginationResultModel<RelatedAssetListViewDTO>>(`${this.baseUrl}api/relatedasset/search`, relatedAssetSearchDTO.relatedAssetQueryDefinition, this.AddPageIdHeader(this.GetStandardHeaders(), pageId), [], paginationModel, [HttpStatusCode.BAD_REQUEST, HttpStatusCode.NOT_FOUND], fieldModel);
    }

    public addRelatedAssets(relatedAssets: RelatedAssetAddDTO[], pageId: string): Observable<PaginationResultModel<RelatedAssetListViewDTO>> {
        return this.PostAuthenticated<PaginationResultModel<RelatedAssetListViewDTO>>(`${this.baseUrl}api/relatedasset/add`, relatedAssets, this.AddPageIdHeader(this.GetStandardHeaders(), pageId));
    }

    public removeRelatedAssets(relatedAssetIds: string[], pageId: string): Observable<PaginationResultModel<RelatedAssetListViewDTO>> {
        return this.DeleteAuthenticated<PaginationResultModel<RelatedAssetListViewDTO>>(
            `${this.baseUrl}api/relatedasset/remove`,
            this.AddPageIdHeader(this.GetStandardHeaders(), pageId),
            [],
            [],
            relatedAssetIds
        );
    }
}
