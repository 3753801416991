import { createReducer, on } from '@ngrx/store';
import { ActionListWidgetStateModel, DocumentGroupWidgetState, ListState, ShapeWidgetState } from '../../../AppState';
import { ListStateReducerHelper } from '../../../helpers/list-state-reducer-helper';
import { ObjectHelper } from '../../../helpers/object-helper';
import { addAssetDocumentDone, addAssetDocumentError, assetDocumentsResetState, assetShapeResetState, getActionsBySearchDone, getActionsBySearchRequestError, getAssetDocumentsDone, getAssetDocumentsError, getAssetShapeDone, getAssetShapeError, removeAssetDocumentDone, updateAssetDocumentDone, updateAssetDocumentError, updateAssetShapeDone, updateAssetShapeError } from './asset-details-inline.actions';

//Documents
export const initialDocumentDraftsDTO: DocumentGroupWidgetState =
{
    triggeredDataSourceId: null,
    listState: [],
    widgetConfigurationId: null
};

export const AssetDocumentsReducer = createReducer<DocumentGroupWidgetState>(initialDocumentDraftsDTO,
    on(getAssetDocumentsDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(getAssetDocumentsError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(addAssetDocumentDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(addAssetDocumentError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(updateAssetDocumentDone, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(updateAssetDocumentError, (state, action) => { return { listState: ListStateReducerHelper.GetUpdatedListState(state.listState, action.dataSourceId, action.documentDraftWidgetState), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(removeAssetDocumentDone, (state, action) => { return { listState: ListStateReducerHelper.removeDocumentListItemFromState(state.listState, action.dataSourceId, action.documentId), triggeredDataSourceId: action.dataSourceId, widgetConfigurationId: action.widgetId } }),
    on(assetDocumentsResetState, (state, action) => initialDocumentDraftsDTO)
);

//Shapes
export const initialShapeDraftDTO: ShapeWidgetState = {
    GetShapeError: null,
    ListState: null,
    UpdateShapeError: null
}

export const AssetShapeReducer = createReducer<ShapeWidgetState>(initialShapeDraftDTO,
    on(updateAssetShapeDone, (_, action) => action.shapeWidgetState),
    on(updateAssetShapeError, (_, action) => action.shapeWidgetState),
    on(getAssetShapeDone, (_, action) => action.shapeWidgetState),
    on(getAssetShapeError, (_, action) => action.shapeWidgetState),
    on(assetShapeResetState, (_, action) => initialShapeDraftDTO)
);

//Actions
export const initialRelatedAssetListState: ListState<ActionListWidgetStateModel>[] = [];

export const ActionListReducer = createReducer<ListState<ActionListWidgetStateModel>[]>(initialRelatedAssetListState,
    on(getActionsBySearchDone, (state, action) => {

        let stateToReturn: ListState<ActionListWidgetStateModel>[] = [];
        stateToReturn = ObjectHelper.deepCopyJsonParse(state);

        if (stateToReturn.length > 0 && stateToReturn.find(s => s.dataSourceId === action.dataSourceId)) {

            const stateEntry = stateToReturn.find(s => s.dataSourceId === action.dataSourceId);
            stateEntry.StateModel = { paginationResult: action.paginationResult, error: null };
        }
        else {
            stateToReturn.push({ dataSourceId: action.dataSourceId, StateModel: { paginationResult: action.paginationResult, error: null } });
        }

        return stateToReturn;
    }),
    on(getActionsBySearchRequestError, (state, action) => {

        let stateToReturn: ListState<ActionListWidgetStateModel>[] = [];
        stateToReturn = ObjectHelper.deepCopyJsonParse(state);

        if (stateToReturn.length > 0 && stateToReturn.find(s => s.dataSourceId === action.dataSourceId)) {

            const stateEntry = stateToReturn.find(s => s.dataSourceId === action.dataSourceId);
            stateEntry.StateModel = {
                paginationResult: {
                    DisplayNextPage: false,
                    Models: [],
                    TotalRecords: 0
                },
                error: action.error
            };
        }

        return stateToReturn;
    })
);
