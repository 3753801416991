import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StructuredDataEntryModel } from '@landadmin/structured-data/lib/models/structured-data-entry-model';
import { Observable } from 'rxjs';
import { StructuredDataEntryCreateDTO } from '../../data-transfer-objects/structured-data/entry/structured-data-entry-create-dto';
import { StructuredDataEntryUpdateDTO } from '../../data-transfer-objects/structured-data/entry/structured-data-entry-update-dto';
import { BASE_URL } from '../../helpers/base-url';
import { PaginationModel, PaginationResultModel } from '../../models/pagination-models';
import { AuthenticatedHttpServiceBase } from '../class-definitions/authenticated-http-service-base';
import { ErrorManagerService } from '../deprecated/error-manager.service';
import { ToastService } from '../deprecated/toast.service';

@Injectable({
    providedIn: 'root'
})
export class StructuredDataEntryHttpService extends AuthenticatedHttpServiceBase {

    constructor(httpClient: HttpClient, @Inject(BASE_URL) private _baseUrl: string, errorManager: ErrorManagerService, toastService: ToastService) {
        super(httpClient, errorManager, toastService);
    }

    public createStrucutredDataEntry(pageId: string, rootNodeId: string, entityId: string, strucutredDataEntryCreateDto: StructuredDataEntryCreateDTO): Observable<null> {
        return this.PostAuthenticated<null>(`${this._baseUrl}api/structureddataentry/pageId/${pageId}/rootNodeId/${rootNodeId}/entityId/${entityId}`, strucutredDataEntryCreateDto, this.GetStandardHeaders());
    }


    public deleteStrucutredDataEntry(pageId: string, rootNodeId: string, entityId: string, parentEntryId: string): Observable<null> {
        return this.DeleteAuthenticated<null>(`${this._baseUrl}api/structuredDataEntry/pageId/${pageId}/rootNodeId/${rootNodeId}/entityId/${entityId}/parentEntryId/${parentEntryId}`, this.GetStandardHeaders(), []);
    }

    public getStructuredDataEntry(pageId: string, rootNodeId: string, entityId: string, paginationModel: PaginationModel): Observable<PaginationResultModel<StructuredDataEntryModel>> {
        return this.GetAuthenticated<PaginationResultModel<StructuredDataEntryModel>>(`${this._baseUrl}api/structuredDataEntry/pageId/${pageId}/rootNodeId/${rootNodeId}/entityId/${entityId}`, this.GetStandardHeaders(), [], paginationModel);
    }

    public updateStructuredDataEntry(pageId: string, rootNodeId: string, entityId: string, strucutredDataEntryUpdateDto: StructuredDataEntryUpdateDTO): Observable<null> {
        return this.PutAuthenticated<null>(`${this._baseUrl}api/structuredDataEntry/pageId/${pageId}/rootNodeId/${rootNodeId}/entityId/${entityId}`, strucutredDataEntryUpdateDto, this.GetStandardHeaders());
    }
}
