<ng-template [ngIf]="loaded === true">
    <table structured-data-table class="{{structuredDataClass}}"
           [instance-id]="instanceId"
           [structured-data]="structuredDataModel"
           [show-headers]="true"
           [add-enabled]="addEnabled"
           [delete-enabled]="deleteEnabled"
           [is-edit-mode]="editMode"
           (structured-data-entry-change)='Update($event)'
           (structured-data-entry-delete)='Delete($event)'
           (structured-data-entry-add)='Add($event)'
           (on-page-change)="Paginate($event)"
           [total-records]="100"
           [pagination-enabled]="paginationEnabled"
           [empty-message]="emptyListMessage">
    </table>
</ng-template>
