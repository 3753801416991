import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[fwAutoFocus]',
})
export class AutoFocusDirective implements OnInit {
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    if (
      (this.elementRef.nativeElement as HTMLElement).tagName === 'P-DROPDOWN' ||
      (this.elementRef.nativeElement as HTMLElement).tagName === 'P-INPUTNUMBER'
    ) {
      this.elementRef.nativeElement.getElementsByTagName('input')[0].focus();
    } else if (
      (this.elementRef.nativeElement as HTMLElement).tagName === 'P-CALENDAR'
    ) {
      setTimeout(() => {
        const pCalendar = this.elementRef.nativeElement as HTMLElement;
        const inputCollection = pCalendar.getElementsByTagName('input');
        inputCollection.item(0).focus();
      });
    } else if (
      (this.elementRef.nativeElement as HTMLElement).tagName ===
      'P-TOGGLEBUTTON'
    ) {
      setTimeout(() => {
        const pToggleButton = this.elementRef.nativeElement as HTMLElement;
        const divCollection = pToggleButton.getElementsByTagName('div');
        divCollection.item(0).focus();
      });
    } else {
      this.elementRef.nativeElement.focus();
    }
  }
}
