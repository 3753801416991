import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TaskWidgetConfiguration } from '../../../../../data-transfer-objects/configuration/task-widget-configuration-view-dto';
import { TaskWidgetDetailsConfigurationViewDTO } from '../../../../../data-transfer-objects/configuration/task-widget-details-configuration-view-dto';
import { TaskListStyle } from '../../../../../enums/configuration/task-list-style';
import { AbstractTaskFacade } from '../../../../../facade/abstract/abstract-task.facade';
import { UIStylingHelper } from '../../../../../helpers/ui-styling-helper';
import { TaskBehaviourContextModel } from '../../../../../models/behaviours/context-models/task/task-behaviour-context-model';
import { SignalRApplicationService } from '../../../../../services/application/signalr-base-application-service';
import { HttpServiceBase } from '../../../../../services/class-definitions/http-service-base';

@Component({
    selector: 'fw-task-widget',
    templateUrl: './task-widget.component.html'
})

export class TaskWidgetComponent implements OnInit, OnDestroy, DoCheck {
    public IsExecutingBehaviour: boolean = false;

    public finishedExecutingBehaviourSubscription: Subscription;
    public signalRLoadedSubscription: Subscription;

    @Input()
    public TaskWidgetConfiguration: TaskWidgetConfiguration;

    @Input()
    public TaskListStyle: TaskListStyle;

    constructor(
        public taskFacade: AbstractTaskFacade<TaskBehaviourContextModel>
    ) { }

    public IsTaskValid(task: TaskWidgetDetailsConfigurationViewDTO): boolean {
        if (task.HasWidgetWithLoadingDependency) {
            return (this.taskFacade._behaviourContext && !this.IsExecutingBehaviour && (HttpServiceBase.RequestCount === 0));
        } else if (task.HasCommit) {
            return (this.taskFacade._behaviourContext && !this.IsExecutingBehaviour && (HttpServiceBase.RequestCount === 0)) &&
                (!this.TaskWidgetConfiguration.Enabled.PageValidation || (this.TaskWidgetConfiguration.Enabled.PageValidation && this.taskFacade._isValid));
        } else {
            return this.taskFacade._behaviourContext && !this.IsExecutingBehaviour && (!this.TaskWidgetConfiguration.Enabled.PageValidation ||
                (this.TaskWidgetConfiguration.Enabled.PageValidation && this.taskFacade._isValid));
        }
    }

    public executeBehaviours(task: TaskWidgetDetailsConfigurationViewDTO): void {

        //TODO: Fix this
        this.IsExecutingBehaviour = true;
        this.taskFacade.ExecuteBehaviour(task.Id, this.TaskWidgetConfiguration.Id);
    }

    public getButtonStyle(): string {

        return UIStylingHelper.getButtonStyle(this.TaskWidgetConfiguration.ButtonStyle);
    }

    public getTaskListButtonStyle(task: TaskWidgetDetailsConfigurationViewDTO): string {

        return UIStylingHelper.getButtonStyle(task.ButtonStyle);
    }

    public getTaskListStyle(): TaskListStyle {
        if (this.TaskWidgetConfiguration.TaskListStyle === null) {
            return TaskListStyle.Dropdown;
        }

        else {
            return this.TaskWidgetConfiguration.TaskListStyle;
        }
    }

    ngDoCheck(): void {

        if (this.taskFacade.singalRLoadedObservable && !this.signalRLoadedSubscription) {
            this.signalRLoadedSubscription = this.taskFacade.singalRLoadedObservable.subscribe((signalRBaseApplicationTaskService: SignalRApplicationService) => {
                if (signalRBaseApplicationTaskService) {
                    this.finishedExecutingBehaviourSubscription = signalRBaseApplicationTaskService.FinishedExecutingBehaviour().subscribe((taskId: string) => {
                        const task = this.TaskWidgetConfiguration.Tasks.find(task => task.Id === taskId);
                        if (task?.Id === taskId) {
                            this.IsExecutingBehaviour = false;
                        }
                    });
                }
            });
        }
    }


    ngOnDestroy(): void {
        if (this.signalRLoadedSubscription) {
            this.signalRLoadedSubscription.unsubscribe();
        }

        if (this.finishedExecutingBehaviourSubscription) {
            this.finishedExecutingBehaviourSubscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.IsExecutingBehaviour = false;
        if (!this.TaskWidgetConfiguration.Enabled) {
            this.TaskWidgetConfiguration.Enabled = {
                PageValidation: false
            };
        }
    }
}
