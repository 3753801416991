<div *ngIf="headerResourceId && isVisible" [ngSwitch]="headerStyle"
     [hidden]="ListConfigurationViewDTO.HideWhileLoading && isLoading" class="list-header">
    <h1 *ngSwitchCase="headerStyleEnum.h1">{{ headerResourceId | transloco}} <span class="required" *ngIf="required">*</span></h1>
    <h2 *ngSwitchCase="headerStyleEnum.h2">{{ headerResourceId | transloco}} <span class="required" *ngIf="required">*</span></h2>
    <h3 *ngSwitchCase="headerStyleEnum.h3">{{ headerResourceId | transloco}} <span class="required" *ngIf="required">*</span></h3>
    <h4 *ngSwitchCase="headerStyleEnum.h4">{{ headerResourceId | transloco}} <span class="required" *ngIf="required">*</span></h4>
    <h5 *ngSwitchCase="headerStyleEnum.h5">{{ headerResourceId | transloco}} <span class="required" *ngIf="required">*</span></h5>
    <h6 *ngSwitchCase="headerStyleEnum.h6">{{ headerResourceId | transloco}} <span class="required" *ngIf="required">*</span></h6>
    <span *ngSwitchCase="headerStyleEnum.body">{{ headerResourceId | transloco}} <span class="required" *ngIf="required">*</span></span>
</div>

<p-table *ngIf="isVisible" #dt
         [hidden]="ListConfigurationViewDTO.HideWhileLoading && isLoading"
         [value]="models"
         [lazy]="true"
         (onLazyLoad)="LoadEntities($event)"
         reflow="true"
         [paginator]="Pageable && !firstTimeLoad"
         [rows]="ListConfigurationViewDTO.PageSize"
         [totalRecords]="totalRecords"
         [showCurrentPageReport]="true"
         [currentPageReportTemplate]="totalRecords > ListConfigurationViewDTO.PageSize ? currentPageReportTemplate : currentPageReportTemplateSinglePage"
         [showPageLinks]="totalRecords > ListConfigurationViewDTO.PageSize"
         [showFirstLastIcon]="totalRecords > ListConfigurationViewDTO.PageSize"
         [ngClass]="getTableStyleClass() + ' ' + getLayoutMode()"
         [loading]="isLoading"
         [columns]="ListConfigurationViewDTO.Fields"
         dataKey="Id"
         [selectionMode]="selectionMode"
         [(selection)]="selectedItems"
         [first]="first"
         [sortField]="ListConfigurationViewDTO.DefaultSort"
         [sortOrder]="ListConfigurationViewDTO.DefaultSortOrder === 2 ? 1 : -1"
         responsiveLayout="scroll"
         (onRowSelect)="onRowSelect()"
         (onRowUnselect)="onRowUnselect()" [class.is-loading]="isLoading">
    <ng-template *ngIf="IsUserList" pTemplate="header" let-columns>
        <tr *ngIf="!isLoading">
            <th *ngFor="let col of columns" [pSortableColumn]="col.PropertyName"
                [pSortableColumnDisabled]="col.PropertyName === 'Roles'" [ngSwitch]="col.PropertyName" class="filter-column">
                <div class="d-flex column-gap-1">
                    <span>{{col.HeaderLabelResourceId | transloco}}</span>
                    <p-sortIcon *ngIf="col.PropertyName !== 'Roles'" [field]="col.PropertyName"></p-sortIcon>
                    <ng-template [ngIf]="ListConfigurationViewDTO.UIFilteringEnabled">
                        <p-columnFilter *ngSwitchCase="'Email'" type="text" matchMode="contains" [field]="col.PropertyName"
                                        display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        <p-columnFilter *ngSwitchCase="'Name'" type="text" matchMode="contains" [field]="col.PropertyName"
                                        display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        <p-columnFilter *ngSwitchCase="'Status'" matchMode="equals" [field]="col.PropertyName"
                                        display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                                        [showApplyButton]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)"
                                            placeholder="Any" optionLabel="Value"></p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                        <ng-template>
                            <p-columnFilter *ngSwitchCase="'Roles'" matchMode="in" [field]="col.PropertyName" display="menu"
                                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                                            [showApplyButton]="false">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect [ngModel]="value" [options]="roles" placeholder="Any"
                                                   (onChange)="filter($event.value)" optionLabel="Value"></p-multiSelect>
                                </ng-template>
                            </p-columnFilter>
                        </ng-template>
                    </ng-template>
                </div>
            </th>
        </tr>
    </ng-template>
    <ng-template *ngIf="!CustomHeaderTemplate && !IsUserList" pTemplate="header" let-columns>
        <tr *ngIf="!isLoading">
            <th *ngIf="showCheckbox">
                <!-- Select Placeholder -->
            </th>
            <th *ngFor="let col of columns" [pSortableColumn]="col.PropertyName" [pSortableColumnDisabled]="!ListConfigurationViewDTO.UISortingEnabled || (col.Metadata?.PropertyDataType !== 'string' && col.Metadata?.PropertyDataType !== 'number' && col.Metadata?.PropertyDataType !== 'date' && col.Metadata?.PropertyDataType !== 'boolean' && col.Metadata?.PropertyDataType !== 'lookup')" [ngSwitch]="col.Metadata?.PropertyDataType" [class.filter-column]="ListConfigurationViewDTO.UIFilteringEnabled || ListConfigurationViewDTO.UISortingEnabled">
                <div class="d-flex column-gap-1">
                    <span>{{col.HeaderLabelResourceId | transloco}}</span>
                    <p-sortIcon *ngIf="ListConfigurationViewDTO.UISortingEnabled && (col.Metadata?.PropertyDataType === 'string' || col.Metadata?.PropertyDataType === 'number' || col.Metadata?.PropertyDataType === 'date' || col.Metadata?.PropertyDataType === 'boolean' || col.Metadata?.PropertyDataType === 'lookup')" [field]="col.PropertyName"></p-sortIcon>
                    <ng-template [ngIf]="ListConfigurationViewDTO.UIFilteringEnabled && col.Metadata?.IsFilterField">

                        <p-columnFilter matchMode=" " *ngSwitchCase="'string'" type="text" [field]="col.PropertyName" display="menu" operator="" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [hideOnClear]="true" [showClearButton]="false" [showApplyButton]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <fw-string-column-filter [InputFilter]="value" (OnFilter)="filter($event)" (OnClearFilter)="filter(undefined)"></fw-string-column-filter>
                            </ng-template>
                        </p-columnFilter>

                        <p-columnFilter [matchModeOptions]="numberMatchModeOptions" *ngSwitchCase="'number'" type="numeric" [field]="col.PropertyName" display="menu" operator="" [showOperator]="false" [showAddButton]="false" [hideOnClear]="true" [showClearButton]="false" [showApplyButton]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <fw-number-column-filter [InputFilter]="value" (OnFilter)="filter($event)" (OnClearFilter)="filter(undefined)"></fw-number-column-filter>
                            </ng-template>
                        </p-columnFilter>

                        <p-columnFilter operator="dates" matchMode=" " *ngSwitchCase="'date'" [field]="col.PropertyName" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false" [hideOnClear]="true">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <fw-date-column-filter [InputFilter]="value" (OnFilter)="filter($event)"></fw-date-column-filter>
                            </ng-template>
                        </p-columnFilter>

                        <p-columnFilter #columnFilter *ngSwitchCase="'lookup'" matchMode="equals" [field]="col.PropertyName" display="menu" operator="" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <fw-lookup-column-filter [Options]="GetLookupFilterOptions(col.Metadata?.LookupInformation.LookupKey, col.Metadata?.LookupInformation?.LookupFilter, col.FilterValues) | async" [InputFilter]="{lookupType: col.Metadata?.LookupInformation.LookupKey,lookupValue: value}" (OnFilter)="filter($event)" (OnClearFilter)="filter(undefined)"></fw-lookup-column-filter>
                            </ng-template>

                        </p-columnFilter>
                    </ng-template>
                </div>
            </th>
            <th *ngIf="deleteEnabled">
                <!-- Delete Placeholder -->
            </th>
        </tr>
    </ng-template>


    <ng-container *ngIf="CustomHeaderTemplate">
        <ng-template pTemplate="header" let-columns>
            <ng-container [ngTemplateOutlet]="CustomHeaderTemplate" [ngTemplateOutletContext]="{$implicit: columns}"></ng-container>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="CustomBodyTemplate && IsSelectable">
        <ng-template pTemplate="body" let-model let-columns="columns">
            <tr [pSelectableRow]="model">
                <ng-container [ngTemplateOutlet]="CustomBodyTemplate" [ngTemplateOutletContext]="{$implicit: model}">
                </ng-container>
            </tr>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="CustomBodyTemplate && !IsSelectable">
        <ng-template pTemplate="body" let-model let-columns="columns">
            <ng-container [ngTemplateOutlet]="CustomBodyTemplate" [ngTemplateOutletContext]="{$implicit: {model: model, columns: columns, showCheckbox: showCheckbox, deleteEnabled: deleteEnabled}}">
            </ng-container>
        </ng-template>
    </ng-container>
    <ng-template *ngIf="!CustomBodyTemplate" pTemplate="body" let-model let-columns="columns">
        <tr *ngIf="!isLoading">
            <td *ngIf="IsSelectable && selectMode === 1">
                <p-tableRadioButton [value]="model"></p-tableRadioButton>
            </td>
            <td *ngIf="IsSelectable && selectMode === 2">
                <p-tableCheckbox [value]="model"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns" [ngSwitch]="col.PropertyName">
                <span class="p-column-title">
                    {{
                    col.HeaderLabelResourceId | transloco
                    }}
                </span>
                <div *ngIf="isDateField(model[col.PropertyName], col); else notDateField">
                    <fw-list-calendar-wrapper [Date]="model[col.PropertyName]" [IsRoutingField]="col.IsRoutingField" (RoutingFieldClicked)="RoutingFieldClickedHandler(col, model)"></fw-list-calendar-wrapper>
                </div>
                <ng-template #notDateField>
                    <a *ngIf="col.IsRoutingField; else readOnlyField" (click)="RoutingFieldClickedHandler(col, model)"
                       href="javascript:void(0)">{{ model[col.PropertyName] }}</a>
                    <ng-template #readOnlyField>
                        {{ model[col.PropertyName] }}
                    </ng-template>
                </ng-template>
                
            </td>
            <td *ngIf="deleteEnabled">
                <span class="pi pi-times" aria-hidden="true" (click)="deleteClick(model.Id)"></span>
            </td>

            <ng-container *ngIf="AdditionalColumnsBodyTemplate" [ngTemplateOutlet]="AdditionalColumnsBodyTemplate"
                          [ngTemplateOutletContext]="{$implicit: model}">
            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length">
                {{ emptyListResourceId | transloco }}
            </td>
        </tr>
    </ng-template>
</p-table>

<p-button [disabled]="AddButtonDisabledState" styleClass="btn btn-secondary" type="button" [label]="dialogLoadButtonText" *ngIf="addEnabled" (onClick)="loadClick()">
</p-button>
