import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserListWidgetConfigurationViewDTO } from 'src/app/data-transfer-objects/configuration/widgets/user-list-widget-configuration-view-dto';
import { UserListViewDTO } from 'src/app/data-transfer-objects/users/user-list-view-dto';
import { Comparator, OrderByDirection } from 'src/app/enums/comparator-enum';
import { FieldType } from 'src/app/enums/field-type-enum';
import { LookupTables } from 'src/app/enums/lookup-table-enum';
import { WhereClauseContactenator } from 'src/app/enums/where-clause-contactenator-enum';
import { LookupModel } from 'src/app/models';
import { PaginationModel, PaginationResultModel } from 'src/app/models/pagination-models';
import { SearchModel } from 'src/app/models/search-model';
import { LookupCacheService } from 'src/app/services/deprecated/lookup-cache.service';
import { IListWidget, NavigateWrapper, OnLoadWrapper } from '../list-widget-interface';
import { ToastService } from '../../../../../../services/deprecated/toast.service';
import { AbstractUserListFacade } from '../../../../../../facade/abstract/abstract-user-list.facade';
import { ListState, UserListWidgetStateModel } from '../../../../../../AppState';
import { GuidHelper } from 'src/app/helpers/guid-helper';

@Component({
    selector: 'fw-user-list-widget',
    templateUrl: './user-list-widget.component.html'
})
export class UserListWidgetComponent implements OnInit, OnDestroy, IListWidget<UserListViewDTO> {

    public PaginationResultModel: PaginationResultModel<UserListViewDTO>;

    public DataSourceId: string;
    public errorMessageResourceId: string = null;
    public first: number = 0;
    public roles: LookupModel[] = [];
    public statuses: LookupModel[] = [];
    public totalRecords: number;
    public users: UserListViewDTO[] = [];

    @Input()
    public UserListWidgetConfigurationViewDTO: UserListWidgetConfigurationViewDTO;

    private subcriptions: Subscription[] = [];

    constructor(
        private lookupService: LookupCacheService,
        private toastService: ToastService,
        private abstractUserListFacade: AbstractUserListFacade
    ) { }

    public NavigateByContext(navigateWrapper: NavigateWrapper): void {

        this.abstractUserListFacade.ExecuteBehaviour(navigateWrapper.fieldConfiguration.PropertyName, this.UserListWidgetConfigurationViewDTO.Id, null, null, navigateWrapper.model.Id);
    }

    public OnLoad(onLoadWrapper: OnLoadWrapper): void {

        const pageId = this.abstractUserListFacade.PageId;
        const widgetId = this.UserListWidgetConfigurationViewDTO.Id;

        const searchModel: SearchModel = new SearchModel();
        searchModel.WhereClauseContactenator = WhereClauseContactenator.And;

        searchModel.SearchFields = [];

        //TODO: to be removed as part of LFF-1320 or once a pattern for filtering has been established
        Object.keys(onLoadWrapper.event.filters).forEach((filterKey) => {
            const filter = onLoadWrapper.event.filters[filterKey];
            let filterValue = filter[0]?.value ?? '';

            if (filterKey === 'Status') {
                filterValue = (filterValue as LookupModel)?.Id;
            }

            if (filterKey === 'Roles') {
                const selectedRoles: LookupModel[] = filter[0]?.value == null ? [] : filter[0].value as LookupModel[];
                filterValue = JSON.stringify(selectedRoles.map((role) => role.Id));
            }

            searchModel.SearchFields.push({
                Field: filterKey,
                Value: filterValue,
                Comparator: (filter === undefined ? Comparator.Like : (filter[0].matchMode === 'contains' ? Comparator.Like : Comparator.Exact)),
                Type: FieldType.String
            });
        });

        const paginationModel: PaginationModel = new PaginationModel();
        paginationModel.Limit = this.UserListWidgetConfigurationViewDTO.PageSize;
        paginationModel.Page = onLoadWrapper.event.first / onLoadWrapper.event.rows + 1;
        paginationModel.OrderByList = [
            {
                Field: onLoadWrapper.event.sortField ?? this.UserListWidgetConfigurationViewDTO.DefaultSort,
                OrderByDirection: onLoadWrapper.event.sortOrder === 1 ? OrderByDirection.Ascending : OrderByDirection.Descending
            }
        ];

        onLoadWrapper.paginationModel = paginationModel;
        onLoadWrapper.searchModel = searchModel;

        //not following pattern here, this will need to be reworked
        //TODO: to be removed as part of LFF-1320
        this.abstractUserListFacade.LoadUserListItems(onLoadWrapper.searchModel, onLoadWrapper.paginationModel, pageId, widgetId, this.DataSourceId);
    }

    ngOnDestroy(): void {
        if (this.subcriptions) {
            this.subcriptions.forEach((subcription) => {
                if (subcription) {
                    subcription.unsubscribe();
                }
            });
        }
    }

    public ngOnInit(): void {

        this.DataSourceId = GuidHelper.NewGuid();

        const getLookupTableUserAccountStatus = this.lookupService.GetLookupTable(LookupTables.UserAccountStatus).subscribe((statuses) => {
            this.statuses = statuses;
        });

        const getLookupTableRoles = this.lookupService.GetLookupTable(LookupTables.Roles).subscribe((roles) => {
            this.roles = roles;
            if (this.UserListWidgetConfigurationViewDTO.UserQueryDefinition && this.UserListWidgetConfigurationViewDTO.UserQueryDefinition.RoleFilter.length > 0) {
                this.roles = this.roles.filter((role) => this.UserListWidgetConfigurationViewDTO.UserQueryDefinition.RoleFilter.includes(role.Id));

            }
        });

        const getUserListSubscription = this.abstractUserListFacade.GetListItems().subscribe((userListWidget: ListState<UserListWidgetStateModel>[]) => {

            if (!userListWidget || userListWidget.length == 0) {
                return;
            }

            const stateEntry = userListWidget.find((alw) => alw.dataSourceId == this.DataSourceId);

            if (stateEntry) {

                if (stateEntry.StateModel.error) {

                    this.errorMessageResourceId = 'Lists.GenericGetError';
                    this.toastService.ShowErrorToast(stateEntry.StateModel.error, [{
                        Message: this.errorMessageResourceId,
                        RouterLink: null,
                        RouterText: null,
                        QueryParameters: null,
                        MessageParameters: null
                    }], true);

                    this.PaginationResultModel = stateEntry.StateModel.paginationResult;

                } else {
                    this.errorMessageResourceId = null;
                    this.PaginationResultModel = stateEntry.StateModel.paginationResult;

                    this.users = this.PaginationResultModel.Models.map((user) => {
                        const roles = this.roles.filter((role) => user.Roles.includes(role.Id)).map((role) => ` ${role.Value}`);
                        return {
                            Id: user.Id,
                            Email: user.Email,
                            Name: user.Name,
                            Status: user.Status,
                            Roles: roles
                        }
                    })

                    this.PaginationResultModel = { Models: this.users, DisplayNextPage: this.PaginationResultModel.DisplayNextPage, TotalRecords: this.PaginationResultModel.TotalRecords };
                }
            }


        });

        this.subcriptions.push(getLookupTableUserAccountStatus)
        this.subcriptions.push(getLookupTableRoles)
        this.subcriptions.push(getUserListSubscription);
    }

}
