<fw-list (OnLoad)="OnLoad($event)"
         (RoutingFieldClicked)="NavigateByContext($event)"
         [ListConfigurationViewDTO]="RelatedAssetListWidgetConfigurationViewDTO"
         [ErrorMessageResourceId]="errorMessageResourceId"
         [PaginationResultModel]="PaginationResultModel"
         [IsUserList]="false"
         [addEnabled]="addEnabled"
         [required]="RelatedAssetListWidgetConfigurationViewDTO.Required"
         [additionalAddData]="dialogData"
         [deleteEnabled]="deleteEnabled"
         [dialogHeaderText]="dialogHeaderText"
         [dialogLoadButtonText]="dialogLoadButtonText"
         [dialogComponentToShow]="dialogComponentToShow"
         (onItemsAddedEvent)="onItemsAdd($event)"
         (onItemDeleteEvent)="onItemDelete($event)"
         [AddButtonDisabledState]="AddButtonDisabled">
</fw-list>