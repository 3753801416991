/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { GuidHelper } from 'src/app/helpers/guid-helper';
import { SiteConfigurationFacade } from '../../../../facade/configuration/site-configuration.facade';
import { StructuredDataLookupFacade } from '../../../../facade/configuration/structured-data-lookup.facade';
import { StructuredDataNodeFacade } from '../../../../facade/configuration/structured-data-node.facade';
import { LanguageDetailViewDTO } from '../../../../data-transfer-objects/language/language-detail-view-dto';
import { DialogApplicationService } from '../../../../services/application/dialog-application.service';
import { SessionApplicationService } from '../../../../services/application/session-application.service';
import { LanguageHttpService } from '../../../../services/http/language-http.service';
import { UserHttpService } from '../../../../services/http/user-http.service';
import { MapService } from '../../../../services/deprecated/map.service';
import { RepresentativesHttpService } from '../../../../services/http/representatives-http.service';
import { AuthenticationHttpService } from 'src/app/services/http/authentication-http.service';

@Component({
    selector: 'fw-user-menu',
    templateUrl: './user-menu.component.html',
})
export class UserMenuComponent implements OnInit, OnDestroy {
    public userName: string = '';
    public representString: string = '';
    public languages: LanguageDetailViewDTO[] = [];
    public currentLanguageId: number;
    private subscriptions: Subscription[] = [];

    constructor(
        private router: Router,
        private mapService: MapService,
        private sessionApplicationService: SessionApplicationService,
        private authenticationHttpService: AuthenticationHttpService,
        private translocoService: TranslocoService,
        public languageService: LanguageHttpService,
        public structuredDataLookupFacade: StructuredDataLookupFacade,
        public structuredDataNodeFacade: StructuredDataNodeFacade,
        private translateService: TranslateService,
        private primeNGConfig: PrimeNGConfig,
        private siteConfigurationFacade: SiteConfigurationFacade
    ) { }

    public get shouldShowLanguageSelector(): boolean {
        return this.languages.length > 1;
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    public ngOnInit(): void {
        const getAllLanguages$ = this.languageService.GetAll();
        const getCurrentLanguage$ = this.languageService.GetCurrentLanguageId();

        const allLanguagesSubscription: Subscription = getAllLanguages$
            .subscribe((languages: LanguageDetailViewDTO[]) => {
                this.languages = languages;
            });

        const currentLanguageSubscription: Subscription = getCurrentLanguage$
            .subscribe((languageId: string) => {
                this.currentLanguageId = Number(languageId);
            });

        forkJoin({
            allLanguages: getAllLanguages$,
            currentLanguageId: getCurrentLanguage$
        })
            .pipe(first())
            .subscribe((e: {
                allLanguages: LanguageDetailViewDTO[];
                currentLanguageId: string;
            }) => {
                const currentLanguage: LanguageDetailViewDTO = e.allLanguages.find(x => x.Id === this.currentLanguageId);

                if (currentLanguage) {
                    this.languageService.setCurrentLanguageLocale(currentLanguage.Locale);
                }
            });


        const calendarLanguageSubscription: Subscription = this.translateService.onTranslationChange.subscribe(() => {
            this.calendarTranslations(this.currentLanguageId.toString());
        });

        this.subscriptions = [allLanguagesSubscription, currentLanguageSubscription, calendarLanguageSubscription];

        this.mapService.refreshCache();
    }

    public login(): void {
        this.sessionApplicationService.login('');
    }

    public register(): void {
        this.sessionApplicationService.register();
    }

    public switchRepresentation(): void {
        this.sessionApplicationService.switchRepresentation();
    }

    public logout(): void {
        this.mapService.DestroyCachedState();
        this.authenticationHttpService.logOut().subscribe();
        this.sessionApplicationService.logout();
        this.structuredDataLookupFacade.ResetState();
        this.structuredDataNodeFacade.ResetState();
        this.siteConfigurationFacade.ResetState();
    }

    public profilePageRedirect(): void {
        this.router.navigate([`page/UserDetails`], {
            queryParams: { UserId: this.sessionApplicationService.currentUser.Id },
        });
    }

    public isAuthenticated(): boolean {
        return this.sessionApplicationService.isAuthenticated;
    }

    public getFullName(): string {
        if (this.sessionApplicationService.currentUser) {
            return this.sessionApplicationService.currentUser.Fullname;
        }
        
        return '';
    }

    public getRepresentation(): string {
        if (this.sessionApplicationService.currentUser) {
            return this.sessionApplicationService.currentUser.RepresenteeName;
        }

        return '';
    }

    public getTitle(): string {
        if (this.userIsRepresentingSelf()) {
            return `${this.getRepresentation()}`;
        }
        else {
            return `${this.getFullName()} ${this.translocoService.translate("UserMenu.Representing")} ${this.getRepresentation()}`;
        }
    }

    public userIsRepresentingSelf(): boolean {
        return GuidHelper.Equals(this.sessionApplicationService?.currentUser?.PersonId, this.sessionApplicationService?.currentUser?.RepresenteeId);
    }

    public hasRepresentation(): boolean {
        if (this.sessionApplicationService.currentUser) {
            return this.sessionApplicationService.currentUserHasRepresentatives;
        }

        return false;
    }

    public setLanguage(language: LanguageDetailViewDTO): void {
        this.currentLanguageId = language.Id;
        this.languageService.SetCurrentLanguageId(String(language.Id));
        this.languageService.setCurrentLanguageLocale(language.Locale);

        window.location.reload();
    }

    private calendarTranslations(languageId: string): void {
        this.translateService.use(`language-${languageId}`);
        this.translateService.get('primeng').subscribe(res => this.primeNGConfig.setTranslation(res));
    }
}
